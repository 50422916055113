/**
 * Created by jerry on 2020/03/23.
 * 项目api
 */
import * as API from '@/api/index'

export default {
    // 获取项目列表
    getProjectList: params => {
        return API.POST('api/project/list',params)
    },
    // 获取全部项目列表
    getProjectListAll: params => {
        return API.POST('api/project/all',params)
    },
    // 获取项目销售范围
    getProjectSaleEntity: params => {
        return API.POST('api/project/projectSaleEntity',params)
    },
    // 获取项目消耗范围
    getProjectConsumeEntity: params => {
        return API.POST('api/project/projectConsumeEntity',params)
    },
    // 项目分类列表
    getValidProjectCategoryValid: () => {
        return API.POST('api/projectCategory/valid')
    },
    // 创建项目分类
    createProjectCategory:params => {
        return API.POST('api/projectCategory/create',params)
    },
    // 创建项目
    createProject:params => {
        return API.POST('api/project/create',params)
    },
    // 更新项目
    updateProject:params => {
        return API.POST('api/project/update',params)
    },
    // 新增销售范围、消耗范围 获取权限范围
    getEntityList:params => {
        return API.POST('api/entity/list',params)
    },
    // 获取产品详情
    getProjectDetails:params => {
      return API.POST('api/project/getProjectDetails',params)
    },
}
